import React from "react";
import BRANDING_COMMON_IDENTITY_DEFINITION_EN from "../files/BRANDING_COMMON_IDENTITY_DEFINITION_EN.pdf";
import BRANDING_COMMON_IDENTITY_DEFINITION_GR from "../files/BRANDING_COMMON_IDENTITY_DEFINITION_GR.pdf";
import LOCAL_ANALYSIS_AND_COMPARATIVE_ASSESSMENT_EN from "../files/LOCAL_ANALYSIS_AND_COMPARATIVE_ASSESSMENT_EN.pdf";
import LOCAL_ANALYSIS_AND_COMPARATIVE_ASSESSMENT_GR from "../files/LOCAL_ANALYSIS_AND_COMPARATIVE_ASSESSMENT_GR.pdf";
import LOCAL_QUALITY_PACTS_AND_PILOT_ACTIONS_EN from "../files/LOCAL_QUALITY_PACTS_AND_PILOT_ACTIONS_EN.pdf";
import LOCAL_QUALITY_PACTS_AND_PILOT_ACTIONS_GR from "../files/LOCAL_QUALITY_PACTS_AND_PILOT_ACTIONS_GR.pdf";
import MAPPING_AND_ASSESSMENT_OF_THERMAL_SPRINGS_EN from "../files/MAPPING_AND_ASSESMENT_OF_THERMAL_SPRINGS_EN.pdf";
import MAPPING_AND_ASSESMENT_OF_THERMAL_SPRINGS_GR from "../files/MAPPING_AND_ASSESMENT_OF_THERMAL_SPRINGS_GR.pdf";

const files = {
  branding_common_identity_definition: {
    en: {
      file: BRANDING_COMMON_IDENTITY_DEFINITION_EN,
      title: "BRANDING COMMON IDENTITY DEFINITION",
    },
    gr: {
      file: BRANDING_COMMON_IDENTITY_DEFINITION_GR,
      title: "ΔΗΜΙΟΥΡΓΙΑ ΣΤΡΑΤΗΓΙΚΗΣ BRANDING – ΟΡΙΣΜΟΣ ΚΟΙΝΗΣ ΤΑΥΤΟΤΗΤΑΣ",
    },
    al: {
      file: BRANDING_COMMON_IDENTITY_DEFINITION_EN,
      title: "BRANDING COMMON IDENTITY DEFINITION",
    },
  },
  local_analysis_and_comparative_assessment: {
    en: {
      file: LOCAL_ANALYSIS_AND_COMPARATIVE_ASSESSMENT_EN,
      title: "LOCAL ANALYSIS AND COMPARATIVE ASSESSMENT",
    },
    gr: {
      file: LOCAL_ANALYSIS_AND_COMPARATIVE_ASSESSMENT_GR,
      title: "ΤΟΠΙΚΗ ΑΝΑΛΥΣΗ ΚΑΙ ΣΥΓΚΡΙΤΙΚΗ ΑΞΙΟΛΟΓΗΣΗ",
    },
    al: {
      file: LOCAL_ANALYSIS_AND_COMPARATIVE_ASSESSMENT_EN,
      title: "LOCAL ANALYSIS AND COMPARATIVE ASSESSMENT",
    },
  },
  local_quality_pacts_and_pilot_actions: {
    en: {
      file: LOCAL_QUALITY_PACTS_AND_PILOT_ACTIONS_EN,
      title: "LOCAL QUALITY PACTS AND PILOT ACTIONS",
    },
    gr: {
      file: LOCAL_QUALITY_PACTS_AND_PILOT_ACTIONS_GR,
      title: "ΤΟΠΙΚΑ ΣΥΜΦΩΝΑ ΠΟΙΟΤΗΤΑΣ ΚΑΙ ΠΙΛΟΤΙΚΕΣ ΔΡΑΣΕΙΣ",
    },
    al: {
      file: LOCAL_QUALITY_PACTS_AND_PILOT_ACTIONS_EN,
      title: "LOCAL QUALITY PACTS AND PILOT ACTIONS",
    },
  },
  mapping_and_assessment_of_thermal_springs: {
    en: {
      file: MAPPING_AND_ASSESSMENT_OF_THERMAL_SPRINGS_EN,
      title: "MAPPING AND ASSESSMENT OF THERMAL SPRINGS",
    },
    gr: {
      file: MAPPING_AND_ASSESMENT_OF_THERMAL_SPRINGS_GR,
      title: "ΚΑΤΑΓΡΑΦΗ ΚΑΙ ΑΞΙΟΛΟΓΗΣΗ ΙΑΜΑΤΙΚΩΝ ΠΗΓΩΝ",
    },
    al: {
      file: MAPPING_AND_ASSESSMENT_OF_THERMAL_SPRINGS_EN,
      title: "MAPPING AND ASSESSMENT OF THERMAL SPRINGS",
    },
  },
};

const FileLinks = ({ lang }) => {
    console.log(lang,files.branding_common_identity_definition[lang].file)
  return (
    <div className="container mt-5">
      <div className="columns">
        <div className="column is-3">
          <a
            href={files.branding_common_identity_definition[lang]?.file}
            target="_blank"
            rel="noreferrer"
          >
            {files.branding_common_identity_definition[lang]?.title}
          </a>
        </div>
         <div className="column is-3">
          <a
            href={files.local_analysis_and_comparative_assessment[lang]?.file}
            target="_blank"
            rel="noreferrer"
          >
            {files.local_analysis_and_comparative_assessment[lang]?.title}
          </a>
        </div>
        <div className="column is-3">
          <a
            href={files.local_quality_pacts_and_pilot_actions[lang]?.file}
            target="_blank"
            rel="noreferrer"
          >
            {files.local_quality_pacts_and_pilot_actions[lang]?.title}
          </a>
        </div>
      <div className="column is-3">
          <a
            href={files.mapping_and_assessment_of_thermal_springs[lang]?.file}
            target="_blank"
            rel="noreferrer"
          >
            {files.mapping_and_assessment_of_thermal_springs[lang]?.title}
          </a>
        </div> 
      </div>
    </div>
  );
};
export default FileLinks;
