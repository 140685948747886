import React from "react";
import Markdown from "react-markdown";
import { graphql, Link } from "gatsby";
import { getImage, StaticImage } from "gatsby-plugin-image";
import FullWidthImage from "../components/FullWidthImage";
import Layout from "../components/Layout";
import Navbar from "../components/Navbar";
import FileLinks from "../components/FileLinks";
import Content, { HTMLContent } from "../components/Content";
import brandStrategy from "../files/brand_strategy.pdf";
import masterPlan from "../files/master_plan.pdf";
import touristGuide from "../files/tourist_guide.pdf";

import A_PHASE_MASTER_PLAN_AMMOUDARAS from "../files/A_PHASE_MASTER_PLAN_AMMOUDARAS.pdf";
import A_PHASE_MASTER_PLAN_XINO_NERO from "../files/A_PHASE_MASTER_PLAN_XINO_NERO.pdf";
import A_PHASE_MASTER_PLAN_XYGKIWN_ZAKYNTHOU from "../files/A_PHASE_MASTER_PLAN_XYGKIWN_ZAKYNTHOU.pdf";
import B_C_PHASE_MASTER_PLAN_AMMOUDARAS from "../files/B_C_PHASE_MASTER_PLAN_AMMOUDARAS.pdf";
import B_C_PHASE_MASTER_PLAN_XINO_NERO from "../files/B_C_PHASE_MASTER_PLAN_XINO_NERO.pdf";
import B_C_PHASE_MASTER_PLAN_XYGKIWN_ZAKYNTHOU from "../files/B_C_PHASE_MASTER_PLAN_XYGKIWN_ZAKYNTHOU.pdf";

import BRANDING_COMMON_IDENTITY_DEFINITION_EN from "../files/BRANDING_COMMON_IDENTITY_DEFINITION_EN.pdf";
import BRANDING_COMMON_IDENTITY_DEFINITION_GR from "../files/BRANDING_COMMON_IDENTITY_DEFINITION_GR.pdf";
import LOCAL_ANALYSIS_AND_COMPARATIVE_ASSESSMENT_EN from "../files/LOCAL_ANALYSIS_AND_COMPARATIVE_ASSESSMENT_EN.pdf";
import LOCAL_ANALYSIS_AND_COMPARATIVE_ASSESSMENT_GR from "../files/LOCAL_ANALYSIS_AND_COMPARATIVE_ASSESSMENT_GR.pdf";
import LOCAL_QUALITY_PACTS_AND_PILOT_ACTIONS_EN from "../files/LOCAL_QUALITY_PACTS_AND_PILOT_ACTIONS_EN.pdf";
import LOCAL_QUALITY_PACTS_AND_PILOT_ACTIONS_GR from "../files/LOCAL_QUALITY_PACTS_AND_PILOT_ACTIONS_GR.pdf";
import MAPPING_AND_ASSESMENT_OF_THERMAL_SPRINGS_EN from "../files/MAPPING_AND_ASSESMENT_OF_THERMAL_SPRINGS_EN.pdf";
import MAPPING_AND_ASSESMENT_OF_THERMAL_SPRINGS_GR from "../files/MAPPING_AND_ASSESMENT_OF_THERMAL_SPRINGS_GR.pdf";

import TOURISTIC_PACKAGES_GR_ENG from "../files/TOURISTIC_PACKAGES_GR_ENG.pdf";
import travel_guide_thermal_springs from "../files/travel_guide_thermal_springs.png";
const ProjectPage = ({ data }) => {
  const PageContent = HTMLContent || Content;
  const { frontmatter: page } = data.page;

  const heroImage = getImage(page.image) || page.image;

  return (
    <Layout>
      <Navbar lang={page.language} slug={data.page.fields.slug} />
      <div className="content">
        <StaticImage
          src="../img/about.jpg"
          alt="contact"
          placeholder="blurred"
          objectFit={"cover"}
          layout="fullWidth"
          aspectratio={3 / 1}
        />
        <section
          className="section section--gradient arrow-section-white"
          style={{ borderBottom: "1px solid rgba(0,0,0,0.1)" }}
        >
          <div className="container">
            <h2 className="title  has-text-weight-light is-bold-light is-size-2 is-size-3-mobile">
              {page.title}
            </h2>
            <PageContent className="content" content={data.page.html} />
          </div>
          <div className="container mt-5">
            <h2 className="title  has-text-weight-light is-bold-light is-size-2 is-size-3-mobile">
              {page.results}
            </h2>
            <div className="columns">
              <div className="column is-3">
                <a href={brandStrategy} download>
                  Recording & Assessing the Thermal Springs - Brand Strategy-AL
                </a>
                <br />
                <br />
                <a href={masterPlan} download>
                  Master Plan-AL
                </a>
                <br />
                <br />
                <a href={touristGuide} download>
                  THERMAL SPRINGS TOURISTIC GUIDE
                </a>
                <br />
                <br />
                <a href={TOURISTIC_PACKAGES_GR_ENG} download>
                  CUSTOMIZED TOURISTIC PACKAGES
                </a>
              </div>
              <div className="column is-3">
                <a href={A_PHASE_MASTER_PLAN_XYGKIWN_ZAKYNTHOU} download>
                  Α' φάση master plan Ξυγκιών Ζακύνθου
                </a>
                <br />
                <br />
                <a href={B_C_PHASE_MASTER_PLAN_XYGKIWN_ZAKYNTHOU} download>
                  Β-Γ' φάση master plan Ξυγκιών Ζακύνθου
                </a>
              </div>
              <div className="column is-3">
                <a href={A_PHASE_MASTER_PLAN_AMMOUDARAS} download>
                  Α' φάση master plan Αμμουδάρας
                </a>
                <br />
                <br />
                <a href={B_C_PHASE_MASTER_PLAN_AMMOUDARAS} download>
                  Β-Γ' φάση master plan Αμμουδάρας
                </a>
              </div>
              <div className="column is-3">
                <a href={A_PHASE_MASTER_PLAN_XINO_NERO} download>
                  Α΄ φάση master plan Ξινό Νερό
                </a>
                <br />
                <br />
                <a href={B_C_PHASE_MASTER_PLAN_XINO_NERO} download>
                  Β-Γ' φάση master plan Ξινό Νερό
                </a>
              </div>
            </div>
            <div className="columns">
             <FileLinks lang={page.language}/>
            </div>
            <h2 className="title  has-text-weight-light is-bold-light is-size-4 is-size-3-mobile">
              {page.thermal_guide}
            </h2>
            <div className="columns">
              <div className="column is-2">
                {/* image for qr code */}
                <img
                  src={travel_guide_thermal_springs}
                  alt="qr code"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default ProjectPage;

export const ProjectPageQuery = graphql`
  query ProjectById($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      fields {
        slug
      }
      html
      frontmatter {
        language
        title
        results
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        thermal_guide
      }
    }
  }
`;
